// $(".check").hide();
$("#mozeusSelect").hide();


const showPrize = (param) => {
  console.log(param);

    switch (param) {
        case "Gold Prize":
            $("#prize").html("gold")
            break;
        case "Silver Prize":
            $("#prize").html("silver")
            break;
        case "Bronze Prize":
            $("#prize").html("bronze")
            break;
        default:
            $("#prize").html("bronze")
            break; 
    }
}

//Event Selector
let localTestData = {
    currentTime: "99-99-2022 23:59:50",
    events: [
            {
                    endDate: "11-30-2022 23:59:59",
                    externalID: "",
                    id: 81510,
                    name: "TEST EVENT 1 (506860)",
                    startDate: "03-30-2021 00:00:00",
            },
            {
                    endDate: "11-30-2022 23:59:59",
                    externalID: "",
                    id: 12345,
                    name: "TEST EVENT 2",
                    startDate: "03-30-2021 00:00:00",
            },
    ],
};

const getEventsAPI = () => {
    if (window.location.host.includes("localhost")) {
            populateEventsDropdown(localTestData.events);

            if (localTestData.events.length === 1) {
                    localStorage.setItem("eventSelectorID", localTestData.events[0].id);

                    setTimeout(function () {
                        $(".start").hide();
                        $(".arrows").hide();
                        $(".data").show();
                    }, 2000);
            } else {
                    setTimeout(function () {
                      $(".check").hide();
                        $("#mozeusSelect").show();
                    }, 2000);
            }
    } else {
            // check if there is any active events today
            // Note: for the current date and time we rely on the server
            fetch("/events", {
                    method: "POST",
                    headers: {"Content-Type": "'application/json'"}, // single quotes are required
            })
            .then(status)
            .then((response) => response.json())
            .then((data) => {
                    console.log("/events call is success. Data: ", data);
                    let activeEventsArray = [];
                    const currentTime = new Date(data.currentTime.replace(/-/g, "/"));

                    if (data["events"].length) {
                            for (let i = 0; i < data["events"].length; i++) {
                                    const eventStartTime = new Date(
                                            data["events"][i]["startDate"].replace(/-/g, "/")
                                    );
                                    const eventEndTime = new Date(
                                            data["events"][i]["endDate"].replace(/-/g, "/")
                                    );

                                    if (currentTime > eventStartTime && currentTime < eventEndTime) {
                if (!data.events[i].name.includes('Placeholder')) {
                    if (!data.events[i].name.includes('Sweeps**')) {
                        if (window.location.origin.includes("smartactivator")) {
                            activeEventsArray.push(data.events[i]);
                        } else {
                            if (!data.events[i].name.includes('TEST')) {
                                activeEventsArray.push(data.events[i]);
                            }
                        }
                    }
                }
                                    }
                            }
                    } else {
                            throw new Error("No active events found");
                    }

                    console.log(activeEventsArray);

                    if (activeEventsArray.length === 1) {
                        localStorage.setItem("eventSelectorID", activeEventsArray[0].id);

                        setTimeout(function () {
                            console.log("SELECTED EVENT ID Value", activeEventsArray[0].id);
                            setEventAPI(activeEventsArray[0].id);
                             $(".start").hide();
                             $(".arrows").hide();
                            $(".data").show();
                        }, 2000)
                    } else {
                      setTimeout(function () {
                      $(".check").hide();
                      $("#mozeusSelect").show();
                    }, 2000);
                    }

                    populateEventsDropdown(activeEventsArray);
            })
            .catch((error) => {
                    if (error.message === "No active events found") {
                           $(".check").text("No events available");
                            throw new Error(error.message);
                    } else {
                            throw new Error("/events call failed: Network Error");
                    }
            });
    }
};

                const status = (response) => {
                    console.log("RESPONSE", response);
                    if (response.status === 200) {
                            return Promise.resolve(response);
                    } else {
                            if (response.status === 400) {
                                    return Promise.reject(new Error("Bad Request"));
                            } else if (response.status === 404) {
                                    return Promise.reject(new Error("Not Found"));
                            } else {
                                    return Promise.reject(new Error(response.statusText));
                            }
                    }
                };

                const setEventAPI = (eventID) => {
                    fetch("/setEvent", {
                            method: "POST",
                            headers: {"Content-Type": "'application/json'"}, // single quotes are required
                            body: JSON.stringify({
                                    eventID: parseInt(eventID),
                            }),
                    })
                    .then(status)
                    .then(() => {
                            console.log("/setEvent call is success");
                            console.log("Submit data to event ID: ", eventID);
                            localStorage.setItem("eventSelectorID", eventID);
                    })
                    .catch((error) => {
                            console.log("Error Message:", error.message);

                            if (window.location.host.includes("localhost")) {
                                    console.log("PROCEED FOR LOCAL HOST");
                            }
                    });
                };

                const populateEventsDropdown = (events) => {
                    let eventSelectionElement = document.getElementById("eventSelector");

                    for (let i = 0; i < events.length; i++) {
                            if (!events[i].name.toLowerCase().includes("selector")) {
                                    let newElement = document.createElement("option");
                                    newElement.value = events[i].id;
                                    newElement.innerHTML = events[i].name.replace(/ *\([^)]*\) */g, "");
                                    eventSelectionElement.appendChild(newElement);
                            }
                    }
                };

$("#submitEventButton").on("click", function() {
    let eventSelected = document.getElementById("eventSelector");
    let eventSelectedValue =
            eventSelected.options[eventSelected.selectedIndex].value;
    let selectLength = document.querySelector("#eventSelector").value.length;

    localStorage.setItem("eventSelectorID", eventSelectedValue);

    if (selectLength > 0) {
            $("#eventSelector").css("border-color", "#FFF");

            console.log("SELECTED EVENT ID Value", eventSelectedValue);
            setEventAPI(eventSelectedValue);
            $(".start").hide();
            $(".arrows").hide();
            $(".data").show();
    } else {
            $("#eventSelector").css("border-color", "#e95023");
    }
});

//Submit on load
let result = '';
const prizePageLoad = () => {
    setResult(result);
    SubForm();
    showPrize(prize);
}

const setResult = (result) => {
    var input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'SmartActivatorWebResult';
    input.value = result;
    document.forms['mozeus'].appendChild(input);

    var timeInput = document.createElement('input');
    timeInput.type = 'hidden';
    timeInput.name = 'SmartActivatorWebTime';
    timeInput.value = stringFromTimeInMilliseconds(new Date().getTime());
    document.forms['mozeus'].appendChild(timeInput);
}

const SubForm = () => {
    $.ajax({
        url: '/submitResult',
        type: 'post',
        data: $('#mozeus').serialize(),
        success: function () {
            console.log("Form Submit success");
        }
    });
}


let data = {
    init: () => {
      $mz.cfg.validation.color.invalid.background.hex = "yellow";
      $mz.cfg.validation.color.valid.background.hex = "white";
      $mz.onload();
      data.events();
    },
    events: () => {
      app.addEvent($("#dataButton"), "click", data.ReportResult);
    },
    ReportResult: () => {
      if (data.validateFormOnSubmit()) {
          app.data.formData = BuildResult(document, "mozeus");
  
          function stringFromTime(time, fullYear)
            {
                function n(n)
                {
                    return n > 9 ? "" + n : "0" + n;
                }
                var d = new Date(time);
                var date = n(d.getDate());
                var months = new Array('01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12');
                var month = months[d.getMonth()];
                var year = d.getFullYear().toString();
                // if (!fullYear)
                //     year = year.substr(year.length - 2, 2);
  
                var hour = n(d.getHours());
                var min = n(d.getMinutes());
                var sec = n(d.getSeconds());
                // MM-dd-yy HH:mm:ss
                // return month + '-' + date + '-' + year + ' ' + hour + ':' + min + ':' + sec;
                
                // YYYY-MM-DD HH24:MI:SS
                return `${year}-${month}-${date} ${hour}:${min}:${sec}`
            }
  
          var time = stringFromTime(new Date().getTime());
  
          app.data.formData += `Time=${time};`
  
          console.log(app.data.formData);
  
          SubmitResultString(app.data.formData);
      }
    },
  
    validateFormOnSubmit: () => {
  
      let email = $mz.validate.element($mz.find("Email"));
      app.invalidField($("#Email"), email);
      let fname = $mz.validate.element($mz.find("FName"));
      app.invalidField($("#FName"), fname);
      let lname = $mz.validate.element($mz.find("LName"));
      app.invalidField($("#LName"), lname);
      let zip = $mz.validate.element($mz.find("Zip"));
      app.invalidField($("#Zip"), zip);
      let mobile = $mz.validate.element($mz.find("Mobile"));
      app.invalidField($("#Mobile"), mobile);
      var member = app.valRadios("radio")
  
      return (
        fname && lname && email && mobile && zip && member
      );
    },
  
  };
  
  
  let app = {
    inputNotAllowed: false,
    buttonDelay: 600,
    inputDelay: 400,
    buttonDuration: 0.2,
    validColor: "white",
    invalidColor: "yellow",
    path: "",
    data: {},
    googleAnalyticsOn: true,
  
    init: view => {
      app.buttonAnimation($(".button"), app.buttonDuration);
      TweenMax.to("main", 0.4, { opacity: 1, delay: 0.2 });
  
      if (view === "start") {
        start.init();
      }
  
      if (view === "sceneSwap") {
        ss.init();
      }
    },
  
    buttonAnimation: (obj, duration) => {
      obj.on("mousedown", function(e) {
        let $this = $(this);
  
        TweenMax.to($this, duration, {
          scale: 0.9,
          onComplete: () => {
            TweenMax.to($this, duration, {
              scale: 1
            });
          }
        });
      });
    },
  
    addEvent: (
      obj,
      eventType,
      callback,
      buttonDelay = app.buttonDelay,
      inputDelay = app.inputDelay
    ) => {
      obj.on(eventType, function(e) {
        e.stopPropagation();
        e.preventDefault();
  
        let $this = $(this);
  
        if (!app.inputNotAllowed) {
          app.inputNotAllowed = true;
  
          let timer = setTimeout(e => {
            clearTimeout(timer);
            callback($this);
          }, inputDelay);
  
          let inputNotAllowedTimer = setTimeout(e => {
            clearTimeout(inputNotAllowedTimer);
            app.inputNotAllowed = false;
          }, buttonDelay);
        }
      });
    },
  
    invalidField: ($obj, valid) => {
      if (!valid) {
        $obj.prev().css("color", app.invalidColor);
      } else {
        $obj.prev().css("color", app.validColor);
      }
    },
  
    valRadios: (id) => {
      var getOptIn = document.getElementById(id);
      var info = getOptIn.getElementsByTagName('input');
      var i;
      for (i = 0; i < info.length; ++ i){
          if (info[i].checked) {
              $('#'+id).css("color", app.validColor);
              return true;
          }
      }//loop group
      $('#'+id).css("color", app.invalidColor);
  
      return false;
    },
  
    valCheckBox: $cb => {
      if ($cb.is(":checked")) {
        $cb.next().css("color", app.validColor);
        return true;
      } else {
        $cb.next().css("color", app.invalidColor);
        return false;
      }
    },
  
    // submitResult: () => {
    //   let data = "";
  
    //   for (const prop in app.data) {
    //     // console.log(app.data[prop]);
  
    //     data += app.data[prop];
    //   }
  
    //   console.log(data);
  
    //   SubmitResultString(data);
    // },
  
    advanceView: (
      fromID,
      toID,
      delay = 0,
      callback = () => {
        console.log("no callback");
      }
    ) => {
      $("html, body, main").animate({ scrollTop: "0px" });
      TweenMax.to(fromID, 0.4, { opacity: 0, delay: delay, display: "none" });
      TweenMax.to(toID, 0.4, {
        opacity: 1,
        delay: delay,
        display: "flex",
        onComplete: callback
      });
    },
  
    toggleSpinner: (display, delay = 0, callback) => {
      if (display == "visible") {
        TweenMax.to("#spinner", 0.4, {
          opacity: 1,
          delay: delay,
          display: "flex",
          onComplete: function() {
            if (callback) {
              callback();
            }
          }
        });
      } else if (display == "hidden") {
        TweenMax.to("#spinner", 0.4, {
          opacity: 0,
          display: "none",
          delay: delay,
          onComplete: function() {
            if (callback) {
              callback();
            }
          }
        });
      } 
    },
  
  };
  
  
 